.login-view {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #161a4e;
}

.login-view .left-img {
  height: 100vh;
  width: calc(100vw - 14.027778rem);
  overflow: hidden;
}

.login-view .left-img>img {
  min-width: 100%;
  height: 105%;
}

.login-view .login-page {
  z-index: 3;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.138889rem;
  background-color: #283237;
}

.login-view .login-page .login-header {
  padding-top: 1.666667rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-view .login-page .login-header img.logo {
  display: block;
  width: 7.222222rem;
  animation: picLogin 3s infinite alternate;
}

.login-view .login-page .login-header .header-title {
  margin-top: 0.277778rem;
  display: flex;
  justify-content: center;
  font-size: 0.722222rem;
  font-weight: 400;
  line-height: 0.611111rem;
  letter-spacing: 0.222222rem;
  text-align: right;
  color: #4698cb;
}

.login-view .login-page .login-title {
  width: 100%;
  padding: 1.111111rem 1.666667rem 0;
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 0.888889rem;
  letter-spacing: .50000002rem;
  text-align: left;
  color: #fff;
}

.login-view .login-page .login-main .input-section {
  display: block;
  margin: 0 auto;
}

.login-view .login-page .login-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.555556rem;
  flex-wrap: wrap;
}

.login-view .login-page .login-footer p {
  white-space: nowrap;
}

.login-view .login-page .login-footer.collapse {
  width: calc(100% - 1.777778rem);
}

.footer {
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  color: #70757a;
  font-size: 0.388889rem;
  line-height: 1.111111rem;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.footer .record-number {
  cursor: pointer;
  color: #70757a;
  border-bottom: 0.027778rem solid transparent;
}

.footer .record-number:hover {
  border-color: #70757a;
}